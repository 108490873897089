import React from "react";
import styled, { keyframes } from "styled-components";

// Animacja obrotu spinnera
const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Kontener spinnera
const SpinnerContainer = styled.div`
	width: 2rem;
	height: 2rem;
	position: relative;
	color: ${({ theme: { colors } }) => colors.blackAlways};
`;

// Ikona spinnera
const SpinnerIcon = styled.svg`
	animation: ${spinAnimation} 2s linear infinite;
	width: 1rem;
	height: 1rem;
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	transform-origin: center center;
`;

const BtnSpinner = () => (
	<SpinnerContainer>
		<SpinnerIcon
			aria-hidden="true"
			focusable="false"
			data-prefix="fas"
			data-icon="spinner"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="currentColor"
				d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
			></path>
		</SpinnerIcon>
	</SpinnerContainer>
);

export default BtnSpinner;
