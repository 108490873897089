// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// ** Custom Components
import Button from "components/base/Button";

// #####################################################

export const StyledFormControlPasswordShowButton = styled(Button)`
	height: 100%;
	background-color: transparent;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
		@media (max-width: ${breakpoints.phone - 1}px) {
			background-color: transparent;
		}
	}
	width: 3.15rem;
	color: ${({ theme: { colors } }) => colors.blackAlways};
	border-radius: var(--border-radius-landing);
	text-align: center;
`;

// #####################################################
