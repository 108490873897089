// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledAuthPanelInputContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	line-height: 1.5;
	padding: 0;
	background-color: var(--color-whiteTrueAlways);
	border: none;
	border-radius: var(--border-radius-landing);
	overflow: hidden;

	${({ isOpen }) =>
		isOpen &&
		css`
			border-bottom: 0;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		`}
`;

// #####################################################
