import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "styles/grid";

const StyledFloatingRegisterForFreeButton = styled.button`
	position: fixed;
	bottom: 0.75rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
	cursor: pointer;
	white-space: normal;
	text-align: center;
	font-size: 1.1rem;
	line-height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: ${({ theme }) =>
		theme.utils.createBoxShadow(["buttonWithText"])};
	background-color: var(--color-secondary);
	opacity: 1;
	border-radius: 2.5rem;
	margin: 0rem;
	padding: 1rem 0.5rem;
	&:hover {
		background-color: var(--color-secondary-darken-300);
	}
	&:hover {
		background-color: var(--color-secondary-darken-300);
	}

	width: calc(100% - 2rem);

	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(${breakpoints.tablet - 1}px);
	}

	@media (min-width: ${breakpoints.desktop}px) {
		width: calc(${breakpoints.desktop - 1}px);
	}

	@media (min-width: ${breakpoints.giant}px) {
		width: calc(${breakpoints.giant - 1}px);
	}

	@media (min-width: ${breakpoints.veryGiant}px) {
		width: calc(${breakpoints.veryGiant - 1}px);
	}
`;

const FloatingRegisterForFreeButton = ({ handleOnClick }) => {
	const { t } = useTranslation(["common", "landing"]);

	return (
		<StyledFloatingRegisterForFreeButton
			onClick={handleOnClick}
			color="secondary"
			size="large"
			block
			label={t("landing:registerForFree")}
			py={4}
			style={{
				fontSize: "1.3rem",
			}}
		>
			{t("landing:registerForFree")}
		</StyledFloatingRegisterForFreeButton>
	);
};

export default FloatingRegisterForFreeButton;
