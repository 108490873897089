// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import BtnSpinnerLanding from "components/layout/Btn/components/BtnSpinnerLanding";
import Block from "components/layout/Block";

// ** Styles Import
import { StyledIconContainer } from "components/layout/Btn/styles/IconContainer.styled";
import { StyledLandingButton } from "containers/Landing/styles/LandingButton.styled";

// #####################################################

const StyledLandingButtonWithSpinner = styled(StyledLandingButton)`
	button[disabled] {
		cursor: no-drop;
	}

	:disabled {
		opacity: 1;
		background-color: var(--color-secondary-darken-500);
	}

	&:not(:disabled) {
		&:hover {
			background-color: var(--color-secondary-darken-300);
		}

		${({ active }) =>
			active
				? css`
						z-index: 1;
						&:hover {
							background-color: var(--color-secondary-darken-300);
						}
						background-color: var(--color-secondary-darken-300);
				  `
				: css`
						z-index: 2;
						&:hover {
							background-color: var(--color-secondary-darken-300);
						}
						&:active {
							background-color: var(--color-secondary-darken-300);
						}
				  `}
	}
`;

const LandingButtonWithSpinner = ({ loading, children, ...props }) => {
	// #####################################################

	return (
		<StyledLandingButtonWithSpinner {...props}>
			{loading ? (
				<Block flex alignCenter>
					<StyledIconContainer role="status">
						<BtnSpinnerLanding />
					</StyledIconContainer>
				</Block>
			) : (
				<Block>{children}</Block>
			)}
		</StyledLandingButtonWithSpinner>
	);
};

// #####################################################

export default LandingButtonWithSpinner;

// #####################################################
