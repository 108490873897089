// ** React Imports
import { memo, useMemo, useState, useContext, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

// ** Redux & Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { getLanding } from "store/modules/mainPages";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import withHydrationOnDemand from "react-hydration-on-demand";
import loadable from "@loadable/component";

// ** Custom Components
import Block from "components/layout/Block";
import Section from "components/layout/Section";
import SectionBackground from "./components/SectionBackground";
import LogoLanding from "./components/LogoLanding";
import FloatingRegisterForFreeButton from "components/layout/Btn/components/FloatingRegisterForFreeButton";
import ScrollToTop from "components/global/ScrollToTop";
const Footer = loadable(() => import("components/global/Footer"));
const FooterWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: Footer.load,
})(Footer);

// ** Landing Components
import AuthPanel from "./components/AuthPanel";

const Welcome = loadable(() => import("./components/Welcome"));
const WelcomeWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: Welcome.load,
})(Welcome);

const StatisticsSection = loadable(() =>
	import("./components/StatisticsSection")
);
const StatisticsSectionWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: StatisticsSection.load,
})(StatisticsSection);

const FeaturesAndMenuSection = loadable(() =>
	import("./components/FeaturesAndMenuSection")
);
const FeaturesAndMenuSectionWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: FeaturesAndMenuSection.load,
})(FeaturesAndMenuSection);

const AboutUsSection = loadable(() => import("./components/AboutUsSection"));
const AboutUsSectionWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: AboutUsSection.load,
})(AboutUsSection);

// ** Custom Hooks
import useOpenGraphMetaTags from "hooks/useOpenGraphMetaTags";
import useScrollPosition from "hooks/useScrollPosition";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// ** Styled Components
import { StyledSectionContent, StyledSectionWelcome } from "./styles";
import { StyledCol as Col } from "styles/components/Col.styled";

// #####################################################

const Landing = () => {
	const { isPhone, isTablet } = useContext(deviceTypeContext);

	const { hash } = useLocation();
	const history = useHistory();

	const dispatch = useDispatch();
	useScrollPosition();

	const language = useSelector((state) => state.global.language);

	const landingDataLoaded = useSelector(
		(state) => state.mainPages.landing.loaded
	);

	const isRegister = hash === "#register";
	const isRecover = hash === "#recover";

	const [activeTab, setActiveTab] = useState(
		isRegister ? "register" : isRecover ? "recover" : "login"
	);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 200) {
				setShowFloatingRegisterForFreeButton(true);
			} else {
				setShowFloatingRegisterForFreeButton(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const [
		showFloatingRegisterForFreeButton,
		setShowFloatingRegisterForFreeButton,
	] = useState(false);

	const { t } = useTranslation(["landing"]);

	const customTitle = useMemo(() => {
		const title = t("landing:title");
		return `${title}`;
	}, [language]);

	// ** Open Graph Meta Tags
	const ogMetaTags = useOpenGraphMetaTags({
		customTitle,
	});

	useEffect(() => {
		if (!landingDataLoaded) {
			dispatch(getLanding({ language }));
		}
	}, [language, landingDataLoaded]);

	useEffect(() => {
		if (history?.location?.state === "register") {
			setActiveTab("register");
		}
	}, [history?.location?.state]);

	// #####################################################

	return (
		<>
			{/* Sekcja HEAD */}
			<Helmet title={customTitle} titleTemplate="%s">
				{ogMetaTags}
			</Helmet>

			<ScrollToTop />

			<div className="landingAlwaysLight">
				{/* Główna zawartość strony */}
				<Section background={<SectionBackground />}>
					<Block flex flexWrap>
						<Col>
							<StyledSectionContent>
								<Block>
									<Link to="/">
										<LogoLanding />
									</Link>
								</Block>
								<Block flex flexWrap>
									<Col hiddenLgDown lg={7}>
										{!isPhone && (
											<WelcomeWithHydrationOnDemand />
										)}
									</Col>
									<Col md={12} lg={5}>
										<Block pt={12}>
											<AuthPanel
												activeTab={activeTab}
												setActiveTab={setActiveTab}
											/>
										</Block>
									</Col>
								</Block>
							</StyledSectionContent>
						</Col>
					</Block>
				</Section>

				<StyledSectionWelcome>
					{(isPhone || isTablet) && (
						<WelcomeWithHydrationOnDemand centerScreenshot />
					)}
				</StyledSectionWelcome>
			</div>

			<StatisticsSectionWithHydrationOnDemand />

			<FeaturesAndMenuSectionWithHydrationOnDemand />

			{language === "pl" && <AboutUsSectionWithHydrationOnDemand />}

			<FooterWithHydrationOnDemand id="footer" />

			{showFloatingRegisterForFreeButton && (
				<FloatingRegisterForFreeButton
					handleOnClick={() => {
						setActiveTab("register");
						window.scrollTo(0, 0);
					}}
				/>
			)}
		</>
	);
};

// #####################################################

export default memo(Landing);
