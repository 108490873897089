// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledLandingButton = styled.button`
	position: relative;
	cursor: pointer;
	white-space: normal;
	text-align: center;
	font-size: 1.1rem;
	line-height: 2rem;
	padding: 0 0.75rem;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: ${({ theme }) =>
		theme.utils.createBoxShadow(["buttonWithText"])};
	background-color: var(--color-secondary);
	opacity: 1;
	border-radius: var(--border-radius-landing);
	margin: 0rem;
	width: 100%;

	&:hover {
		background-color: var(--color-secondary-darken-300);
	}
`;

// #####################################################
