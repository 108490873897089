// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

// TODO: Rozważyć ujednolicenie komponentów, pownieważ po zmianach
// inputy na landingu wyglądają analogicznie jak w innychm miejscach

export const StyledAuthPanelInput = styled.input`
	display: block;
	width: 100%;
	background-color: var(--color-whiteTrueAlways-darken-300);
	border-radius: var(--border-radius-landing);
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	color: ${({ theme: { colors } }) => colors.blackAlways};

	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}

	&:focus {
		background-color: var(--color-whiteTrueAlways);
	}

	${({ active }) =>
		active &&
		css`
			background-color: var(--color-whiteTrueAlways);
		`}

	${({ register }) =>
		register &&
		css`
			height: 42px;
			padding: 0 1rem;
		`}
`;

// #####################################################
